<template>
	<PopupLayer class="size-px-16">
		<template
			v-slot:body
		>
			<div
				class="width-500 ma-auto radius-20"
			>
				<div class="pa-10-20 bg-primary flex-row justify-space-between">
					<h5 class="color-white">게시판 추가</h5>
					<v-icon
						class="color-white"
						@click="$emit('cancel')"
					>mdi-close-circle</v-icon>
				</div>

				<div class="bg-white pa-30">
					<div>
						<label class="h5" for="cartelDesc">게시판 이름</label>
						<div class="mt-20 box radius-20 flex-row justify-space-between items-center">
							<input
								v-model="item_board_config.board_name"
								type="text" id="cartelDesc" placeholder="게시판 이름을 입력하세요." maxlength="20"
								class="flex-1 pa-10-20"
							>

							<v-icon
								v-if="item_board_config.board_name"
								@click="item_board_config.board_name = ''"
								class="mr-10 size-px-20"
							>mdi-close-circle</v-icon>

							<span class="mr-20 size-px-14">{{ item_board_config.board_name.length }}/20</span>
						</div>
					</div>
					<div class="mt-20">
						<div class="flex-row justify-space-between items-center">
							<label class="h5" for="">게시판 공개 설정</label>
							<div class="toggle-group h_unset">
								<span
									class="btn-toggle " :class="{ on: item_board_config.release_fg == 'Y'}"
									@click="item_board_config.release_fg = item_board_config.release_fg == 'Y' ? 'N' : 'Y' "
								><em class="hide">가입 승인</em></span>
							</div>
						</div>
						<div class="mt-10 color-gray">
							카르텔에 가입된 회원에게만 게시판을 공개합니다
						</div>
					</div>

					<hr class="under-line mt-20">

					<div class="mt-20">

						<h5>게시판 권한 설정</h5>

						<div class="mt-10 color-gray">
							게시판 글 읽기 및 작성 권한을 설정합니다.
						</div>
					</div>

					<hr class="under-line mt-20">

					<div
						class="mt-10"
						style="height: 320px; overflow: auto"
					>
						<div
							v-for="grade in items_grade"
							:key="'grade_' + grade.cartl_member_grade_number"
							class="mt-20"
						>
							<h5>{{ grade.cartl_member_grade_name}}</h5>
							<div class="mt-10 " >
								<ul class="box-permission flex-row justify-start items-center"  >
									<template
										v-for="p in items_permission"
									>
									<li
										v-if="p.code != 'change_fg'"
										:key="'grade_' + grade.cartl_member_grade_number + '_' + p.code"
										class=""
										@click="setP(grade, p.code)"
									>
										<v-icon
											v-if="grade.cartl_member_div_code == 'CA02500001'"
										>mdi-checkbox-marked-circle</v-icon>

										<template
											v-else
										>
											<v-icon
												v-if="grade[p.code] != 'Y'"
											>mdi-checkbox-blank-circle-outline</v-icon>
											<v-icon
												v-else
												class="color-primary"
											>mdi-checkbox-marked-circle</v-icon>
										</template>
										<button class="size-px-16 ml-5">{{  p.name }}</button>
									</li>
									</template>
								</ul>
							</div>

							<div
								v-if="grade.cartl_member_div_code != 'CA02500003'"
								class="mt-10 items-center"
								@click="setP2(grade)"
							>
								<v-icon
									v-if="grade.cartl_member_div_code == 'CA02500001'"
								>mdi-checkbox-marked-circle</v-icon>

								<template
									v-else
								>
									<v-icon
										v-if="grade.admin_authority_fg != 'Y'"
									>mdi-checkbox-blank-circle-outline</v-icon>
									<v-icon
										v-else
										class="color-primary"
									>mdi-checkbox-marked-circle</v-icon>
								</template>
								<button class="size-px-16 ml-5">게시판 권한(사용자 글 삭제 및 이동)</button>
							</div>
						</div>
					</div>

					<hr class="under-line mt-20">

					<div class="mt-20 text-center">
						<button
							class="btn-inline btn-primary radius-20"
							@click="postBoardConfig"
						>저장</button>
					</div>
				</div>
			</div>

			<mafia126
				v-if="is_126"
				:user="user"
				:cartel="item_cartel"
				:board_config="item_board_config"

				@click="is_126 = false"
				@cancel="is_126 = false"

				style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; background-color:  white"
			></mafia126>
		</template>
	</PopupLayer>
</template>

<script>
import mafia126 from "@/view/Cartel/mafia126"
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'mafia125'
	, components: {PopupLayer, mafia126 }
	, props: ['user', 'board']
	, data: function(){
		return {
			program: {
				name: '게시판 추가'
				, title: '게시판 추가'
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'
				, bg_contents: 'bg-gray01'
			}
			, item_cartel: {}
			, item_board_config: {
				board_name: ''
				, cartl_number: this.$route.params.idx
				, user: this.user
				, release_fg: 'Y'
				, all_read_fg: true
				, all_write_fg: true
				, board_authority: []
			}
			, is_126: false
			, items_grade: []
			, items_permission: [
				{ code: 'all_fg', name: this.$language.common.all}
				, { code: 'inquiry_fg', name: this.$language.common.lookup}
				, { code: 'reg_fg', name: this.$language.common.register}
				, { code: 'delete_fg', name: this.$language.common.delete}
			]
		}
	}
	, computed: {
		is_save: function(){
			let t = false
			if(this.item_board_config.board_name != ''){
				t = true
			}
			t = true
			return t
		}
		, text_title: function(){
			let t = this.program.title
			if(this.$route.params.b_id){
				t = '게시판 수정'
			}
			return t
		}
		, board_authority: function(){
			console.log('items_grade', this.items_grade)
			return this.items_grade.filter(function(item){

				return item
			})
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBoardConfig: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.board.board_number
					}
					, type: true
				})
				if(result.success){
					this.item_board_config = result.data
					this.$set(this.item_board_config, 'board_number', this.board.board_number)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBoardPermission: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_board_permission
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.board.board_number
					}
					, type: true
				})
				if(result.success){
					this.items_grade = result.data.board_authority
					this.items_grade.sort((b, a) => {
						console.log(b, a)
						return 1
					})
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, updateBoardConfig: async function(){

		}

		, postBoardConfig: async function(){
			try{
				if(!this.item_board_config.board_name){
					throw '게시판 이름을 입력하세요'
				}
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.post_board_config
				if(this.board.board_number){
					url = this.$api_url.api_path.post_board_config_modify
				}
				const result = await this.$request.init({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_name: this.item_board_config.board_name
						, board_number: this.item_board_config.board_number
						, board_release_fg: this.item_board_config.release_fg
						// , all_write_fg: this.item_board_config.all_write_fg ? 'Y' : 'N'
						// , all_read_fg: this.item_board_config.all_read_fg ? 'Y' : 'N'
						, board_authority: this.board_authority
					}
					, type: true
				})
				if(result.success){
					this.$emit('click')
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, setP2: function(obj){
			if(obj.admin_authority_fg == 'Y'){
				this.$set(obj, 'admin_authority_fg', 'N')
			}else{
				this.$set(obj, 'admin_authority_fg', 'Y')
			}
		}
		, setP: function(obj, code){

			if(obj[code] == 'Y'){
				this.$set(obj, code, 'N')
			}else{
				this.$set(obj, code, 'Y')
			}

			if(code == 'all_fg'){
				if(obj[code] == 'Y') {
					this.items_permission.forEach((i) => { this.$set(obj, i.code, 'Y')})
				}else{
					this.items_permission.forEach((i) => { this.$set(obj, i.code, 'N')})
				}
			}else{
				if(obj[code] != 'Y'){
					this.$set(obj, 'all_fg', 'N')
					if(code == 'inquiry_fg'){
						this.$set(obj, 'reg_fg', 'N')
						this.$set(obj, 'delete_fg', 'N')
					}
				}else{
					if(code == 'reg_fg' || code == 'delete_fg'){
						this.$set(obj, 'inquiry_fg', 'Y')
					}
					let t = true
					this.items_permission.forEach((i) => {
						console.log(i.code, obj[i.code])
						if(i.code != 'all_fg'){
							if(!obj[i.code]){
								this.$set(obj, 'all_fg', 'N')
								t = false
							}
						}
					})

					if(t){
						this.$set(obj, 'all_fg', 'Y')
					}
				}
			}
		}
		, getCartelGrade: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_member_grade_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.items_grade = result.data.cartl_member_grade_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, async created() {

		await this.getCartelGrade()
		if(this.board.board_number){
			await this.getBoardConfig()
			await this.getBoardPermission()
		}

		console.log('mafia125', this.$route.query)
	}

	, watch: {
		item_board_config: {
			deep: true
			, immediate: true
			, handler: function (call){
				console.log(call)
			}
		}
	}
}
</script>

<style>
	.box-permission li {
		display: inline-block;
		margin-right: 10px;
	}
</style>